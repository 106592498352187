/* You can add global styles to this file, and also import other style files */
@import 'bootstrap-4-grid/css/grid.min.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html, body { width: 100%;}
body { margin: 0; }

* {
  font-family: 'Roboto', sans-serif;
  line-height: 1;
  font-weight: 400;
}

.supline {
  font-size: 16pt;
  font-weight: 200;
  text-align: center;
  line-height: 1.3;
  color: rgb(122, 31, 19);
}

.headline {
  font-size: 25pt;
  font-weight: 200;
  text-align: center;
  line-height: 1.3;

  b {
    font-weight: 400;
  }
}

.subline {
  font-size: 12pt;
  font-weight: 200;
  text-align: center;
  line-height: 1.3;
  padding: 20px 10%;
}

.spacer {
  flex: 1 1 auto;
}


.flexbox {
  display: flex;
}

.navigation-bar {

  &.transparent {
    background-color: transparent;

    .logo, .brand {
      color: white;
      text-shadow: 0px 1px 5px rgba(66,28,18,0.37);
    }
  }


  .mat-flat-button {
    margin-left: 10px;
  }
}

.dark-background {
  background-color: #303030;
  width: 100%;
}
